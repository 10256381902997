import ActivityMain from './activity/activityMain';
import ActivityMedium from './activity/activityMedium';
import ActivityPic from './activity/activityPic';
export default function ActivityPage() {
  return (
    <>
      <ActivityMain />
      <ActivityMedium/>
    </>
  );
}
